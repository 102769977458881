import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function ApplicationProcess() {
  return (
    <Layout>
      <SEO
        title="Online Registration Process | Chinmaya Vidyalaya Vasant Vihar"
        description="Know more about Online registration process, fee structure, admission Form, and documents required in Chinmaya Vidyalaya Vasant Vihar"
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="Chinmaya vidyalaya vasant vihar, chinmaya trust, chinmaya mission, online vidyalaya,online admission,schools in delhi,online registrations"
      />
      <div className="applicationprocess">
        <div className="applicationprocess__section1">
          <h1 className="heading--white">Process For Online Registration</h1>

          <h2>Step 1</h2>
          <p>
            To register, please fill the complete details on the online form,
            and pay the online registration fee of Rs. 25 (non-refundable).
            Submission of the online Registration form is a request for
            admission and does not guarantee admission.
            <br />
            <br /> Please note{" "}
            <strong>
              incomplete forms will not be considered for admission.
            </strong>
          </p>

          <h2>Step 2</h2>
          <p>
            After filling the online form, please take a printout of the form,
            along with the online payment receipt, on white A4 size paper.
            Attach photocopies of all the required documents as detailed below
            in the next section, with the printed form.
            <br />
            <br />
            Kindly note{" "}
            <strong>
              this printed form, along with photocopies of documents, is to be
              submitted at the time of verification, only after selection.
            </strong>
          </p>

          <h2> Next steps:</h2>
          <p>
            The list of shortlisted candidates will be displayed on the school
            website and notice boards, on dates as listed in the Schedules &
            Timelines section. The school does not conduct interviews or
            interactions with the children.
          </p>

          <div className="applicationprocess__section1__buttons">
            <a href="#" className="btn-white">
              Online Admission Form
            </a>

            <a href="/fee-structure/" className="btn-white">
              View Fee Structure
            </a>

            <Link to="/bus-routes/" className="btn-white">
              Current Bus Routes
            </Link>
          </div>
        </div>
        <div className="tel">
          FOR ADMISSION ENQUIRY CALL -{" "}
          <a href="tel:9999834424">
            <strong>9999834424</strong>
          </a>
        </div>

        <div className="applicationprocess__section2">
          <h2 className="heading">Document Checklist</h2>
          <br />
          <p>
            The list of documents to be enclosed with the filled-up registration
            form is as follows. School authorities retain the right to cancel
            admissions if information submitted is found to be false.
          </p>

          <div className="applicationprocess__section2__buttons">
            <a
              href="https://www.chinmayavvdelhi.ac.in/assets/pdfs/admission22-23/DOCUMENT-REQUIRED-NURSERY.pdf"
              target="_blank"
              className="btn-dark"
              download
            >
              Checklist For Nursery <i className="fas fa-file-download"></i>
            </a>

            <a
              href="https://www.chinmayavvdelhi.ac.in/assets/pdfs/admission22-23/Documents-required-for-KG-&-1.pdf"
              target="_blank"
              className="btn-dark"
              download
            >
              Checklist For KG - II <i className="fas fa-file-download"></i>
            </a>

            <a
              href="https://www.chinmayavvdelhi.ac.in/assets/pdfs/admission22-23/Documents-required-for-II-to-IX.pdf"
              target="_blank"
              className="btn-dark"
              download
            >
              Checklist For II - IX <i className="fas fa-file-download"></i>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
